<template>
  <view-card-template :loaded="loaded">
    <!-- <template #viewHeader>
      <h1>Voting</h1>
    </template> -->

    <template #cardTitle>
      {{ ballot && ballot.title ? `Ballot for "${ballot.title}"` : "Loading.." }}
    </template>

    <template #cardBody>

      <!-- START Ballot Form -->
      <div
        class="d-flex flex-column"
        style="height: 100%"
      >
        <v-container
          class="px-6 py-4 d-flex flex-column justify-space-between"
          >
          <!-- style="height: 100%" -->

          <!-- Voting Page Description -->
          <div
            v-if="ballot && ballot.votingPageDescription && ballot.votingPageDescription.length"
            class="d-flex flex-column"
          >
            <h4>Description</h4>
            <div class="mb-2">
              <div v-html="ballot.votingPageDescription"></div>
            </div>
          </div>

          <!-- Sorting starts HERE -->
          <v-divider/>
          <v-row no-gutters class="mb-2 mt-4"> 
            <div class="w-100 d-flex align-center justify-center">
                <div class="d-flex align-center">
            <div class="bold">Nominees {{sortAlphabetical === 'asc' ? 'ascending' : 'descending'}} by {{ sortSchool === 'teamName' ? 'school' : 'last name' }} </div>  
            </div></div>           
            <v-spacer/>
            <v-col cols="auto" class="mr-4">
              <div class="w-100 d-flex align-center justify-center">
                <div class="d-flex align-center">
                  <div class="bold">Sort by: </div>
                </div>
              </div>
            </v-col>
            <v-col cols="auto" class="mr-4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="sortAlphabetical === 'asc' ? sortAlphabetical = 'desc' : sortAlphabetical = 'asc'"
                    icon
                  >
                    <v-icon   
                      color="blue-grey darken-1"
                      small
                    >
                      {{ sortAlphabetical === 'asc' ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending ' }}
                    </v-icon>
                  </v-btn>
                </template>
                sorting ASC or DESC
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="sortSchool === 'lastName' ? sortSchool = 'teamName' : sortSchool = 'lastName'"
                    icon
                  >
                    <v-icon   
                      color="blue-grey darken-1"
                      small
                    >
                      {{ sortSchool === 'lastName' ? 'mdi-group' : 'mdi-ungroup' }}
                    </v-icon>
                  </v-btn>
                </template>
                Sorting by school or last name
              </v-tooltip>    
            </v-col>
          </v-row> 
          <!-- Sorting ends HERE --> 

          <!-- START Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && ballot.awards.length"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(table, index) in tables"
              :key="index"
            >
              <v-expansion-panel-header
                @click="fetchTableStats(table)"
              >
                <div class="d-flex justify-space-between">              
                  <h4
                    :style="{color: inReview && table.award.numVotesAllowed - table.votes.length != 0 ? 'red' : 'black'}"
                  >
                    {{ table.award.awardType.title != 'Preseason Poll' ? table.award.awardType.title : ballot.title }}
                  </h4>
                  <h4
                    class="px-4"
                    :style="{color: inReview && table.award.numVotesAllowed - table.votes.length != 0 ? 'red' : 'black'}"
                  >
                    ({{ table.award.numVotesAllowed - table.votes.length }} Vote{{ table.award.numVotesAllowed - table.votes.length == 1 ? '' : 's' }} Remaining)
                  </h4>
                </div>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content class="panel-with-no-padding">
                <v-skeleton-loader
                  v-if="table.isFetchingStats"
                  type="table-heading, table-heading"
                  elevation="0"
                  tile
                  height="120px"
                />
                <div
                  v-else
                >
                  <div
                    class="w-100"
                    style="background-color: #F5F5F5"
                  >
                    <v-row 
                      class="d-flex align-center px-4 py-2"
                      no-gutters
                    >
                      <v-col>
                        <v-chip-group
                          v-model="table.statGroupsToShow"
                          active-class="primary--text"
                          @change="updateStatHeadersBasedOnFiltering(table)"
                          multiple
                          column
                        >
                          <v-chip
                            v-for="statGroup in table.award.statGroups"
                            :key="statGroup.id"
                            :value="statGroup.name"
                            filter
                          >
                            {{ statGroup.name.replace(/([A-Z])/g, ' $1').trim() /* Add space before capital letters */ }}
                          </v-chip>
                          <v-chip
                            value="confOnly"
                            filter
                          >
                            Show conf only
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- START Award Nominees Table -->
                  <v-data-table
                    :headers="[table.keyHeaders, table.statHeaders].flat()"
                    :items="tableNominationsSorted(table.nominations, sortSchool, sortAlphabetical)"
                    :items-per-page="ballot.numDisplayedNominations != null ? ballot.numDisplayedNominations : 5"
                    :expanded.sync="table.expanded"
                    class="table-with-padded-stats"
                    show-expand
                  > 
                    <template #item="{ item, expand, isExpanded }">
                      <tr
                      :style="{'background-color': table.votes.includes(item) ? '#12dd1266' : ''}"
                      >
                        <td
                          @click="addVote(item, table)"
                          class="font-weight-medium nominee-cell"
                          :style="{ cursor: canVote(item) ? 'pointer' : 'auto', 'background-color': table.votes.includes(item) ? '#18de17' : '' }"
                        >
                          <div>
                            <div>
                              {{ item.firstName.split('/')[0] }} {{ item.lastName.split('/')[0] }}
                              {{
                                table.award.awardType.doubleNomination ?
                                `/ ${item.firstName.split('/')[1] } ${ item.lastName.split('/')[1]}` :
                                ''
                              }}
                            </div>
                            <div class="text-caption">({{ item.teamName }})</div>
                          </div>
                        </td>
                        <td
                          v-if="table.award.awardType.title != 'Preseason Poll'"
                        >
                          <v-btn
                            @click="expand(!isExpanded)"
                            icon
                          >
                            <v-icon
                              :style="{'transform': isExpanded ? 'rotate(-180deg)' : ''}"
                              style="transition: transform .3s;"
                            >
                              mdi-chevron-down
                            </v-icon>
                          </v-btn>
                        </td>
                        <!-- Marker for conference stats vs overall stats -->
                        <td
                          v-if="ballot.ballotType != 3 && table.statHeaders.length > 0"
                          class="mx-0 px-0"
                          style="font-size: 0.755rem;"
                        >
                          <div class="d-flex flex-column align-end">
                            <div style="opacity: 0;" v-show="!table.statGroupsToShow.includes('confOnly')">(overall)</div>
                            <div>(conf)</div>
                          </div>
                        </td>
                        <!-- Stats Go Here -->
                        <td
                          v-for="(statHeader, index) in table.statHeaders"
                          @click="addVote(item, table)"
                          :key="index"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-if="item.hasStats && ballot.ballotType == 3"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{
                                  item.totals[statHeader.stat.mappingGroupName] && item.totals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                    ? item.totals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                    : "-"
                                }}
                              </div>
                              <div
                                v-else-if="item.hasStats && ballot.ballotType != 3"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="d-flex flex-column align-start">
                                  <div  v-show="!table.statGroupsToShow.includes('confOnly')">
                                    {{
                                      item.hasOverallTotals && item.overallTotals[statHeader.stat.mappingGroupName] && item.overallTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        ? item.overallTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        : "-"
                                    }}
                                  </div>
                                  <div>
                                    {{
                                      item.hasConferenceTotals && item.conferenceTotals[statHeader.stat.mappingGroupName] && item.conferenceTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        ? item.conferenceTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        : "-"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                v-bind="attrs"
                                v-on="on"
                              >
                                -
                              </div>
                            </template>
                            {{ statHeader.stat.statGroupName }} | {{ statHeader.stat.name}}
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                    <template 
                      v-slot:expanded-item="{ item, headers }"
                    >
                      <td
                        :colspan="headers.length"
                        style="background-color: #ffffeb;"
                      >
                        <div
                          class="d-flex"
                          style="font-size: 0.775rem; width: min(1024px, 80vw); position: sticky; left: 0; z-index: 2;"
                        >
                          <!-- Jersey, Position, Class Year, Hometown, High School  -->
                          <div
                            class="d-flex flex-row mt-4 mx-6"
                          >
                            <div
                              v-for="(infoItem, index) in item.packagedSupportingInfoItems"
                              :key="index"
                              class="d-flex align-center"
                            >
                                <span style="font-weight: bolder;">{{infoItem.label}}:&nbsp;</span>{{infoItem.value}}
                                <v-divider v-if="index < item.packagedSupportingInfoItems.length - 1" class="mx-2" vertical/>
                            </div>
                          </div>
                        </div>
                        <div
                          style="width: min(1024px, 80vw); position: sticky; left: 0; z-index: 2;"
                        >
                          <div
                            v-html="item.supportingInformation"
                            class="mt-2 mx-6"
                          ></div>
                        </div>
                        <v-data-table
                          v-if="ballot.ballotType == 3 && item.hasStats"
                          :headers="[item.weeklyKeyHeaders, item.weeklyStatHeaders].flat()"
                          :items="item.games"
                          :items-per-page="-1"
                          style="background-color: #ffffeb;"
                          hide-default-footer
                          dense
                          flat
                        >
                          <template #item="itemProps">
                            <tr>
                              <td>{{ itemProps.item.opponent }}</td>
                              <td>{{ formatDate(itemProps.item.date) }}</td>
                              <td>{{ `${itemProps.item.result} ${itemProps.item.score ? itemProps.item.score : ""}` }}</td>
                              <!-- Stats Go Here -->
                              <td
                                v-for="(statHeader, index) in item.weeklyStatHeaders"
                                :key="index"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{on, attrs }">
                                    <div
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ 
                                        itemProps.item[statHeader.stat.mappingGroupName]
                                        && itemProps.item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                          ? itemProps.item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                          : "-"
                                      }}
                                    </div>
                                  </template>
                                  {{ statHeader.stat.statGroupName }} | {{ statHeader.stat.name}}
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <v-divider/>
                      </td>
                    </template>

                  </v-data-table>
                  <!-- END Award Nominees Table -->
                </div>

                <v-divider v-if="table.votes.length"/>

                <!-- START Votes Drag-n-drop -->
                <div>
                  <v-container v-if="rankVotesTeam && countVotes(table) > 0">
                    <v-card elevation="2" v-for="(row, rowIndex) in table.tableVoteRankTeam" :key="rowIndex" class="my-2 d-flex flex-column align-center">
                      <v-card-title>Team {{ rowIndex + 1 }}</v-card-title>
                      <v-card-text>
                        <draggable
                          v-model="table.tableVoteRankTeam[rowIndex]"
                          group="test"
                          class="d-flex flex-column align-center bordered"
                        >
                          <v-card
                            v-for="(vote, index) in row"
                            :key="index"
                            class="my-1"
                            width="50%"
                            hover
                          >
                            <v-row no-gutters>
                              <v-col cols="auto">
                                <v-btn icon>
                                  <v-icon>mdi-drag-horizontal</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <div
                                  class="d-flex justify-center align-center"
                                  style="height: 100%"
                                >
                                  {{ vote.firstName }} {{ vote.lastName }}
                                </div>
                              </v-col>
                              
                              <v-col cols="auto">
                                <v-btn
                                  @click="removeVote(vote, table, rowIndex)"
                                  icon
                                  color="red accent-2"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </draggable>
                      </v-card-text>
                    </v-card>
                  </v-container>
                  <div  class="my-2 d-flex flex-column align-center" v-if="!rankVotesTeam && table.votes.length">
                    <h4 class="mb-1">Votes Cast:</h4>

                    <draggable
                      :list="table.votes"
                      @start="dragging = true"
                      @end="dragging = false"
                      style="width: 100%;"
                      class="d-flex flex-column align-center"
                    >
                      <v-card
                        v-for="(vote, index) in table.votes"
                        :key="index"
                        class="my-1"
                        width="50%"
                        hover
                      >
                        <v-row no-gutters>
                          <v-col cols="auto">
                            <v-btn icon>
                              <v-icon>mdi-drag-horizontal</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <div
                              class="d-flex justify-center align-center"
                              style="height: 100%"
                            >
                              #{{ index + 1 }} - 
                              {{ vote.firstName.split('/')[0] }} {{ vote.lastName.split('/')[0] }}
                              {{
                                table.award.awardType.doubleNomination ?
                                `/ ${vote.firstName.split('/')[1] } ${ vote.lastName.split('/')[1]}` :
                                ''
                              }}
                            </div>
                          </v-col>
                          
                          <v-col cols="auto">
                            <v-btn
                              @click="removeVote(vote, table)"
                              icon
                              color="red accent-2"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </draggable>
                  </div>
                </div>
                <!-- END Votes Drag-n-drop -->
              </v-expansion-panel-content>
              
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Expansion Panels -->

          <!-- Loading stuff goes here? -->
          <div v-else></div>

          <!-- START Page Actions -->
          <v-card-actions style="margin-top: auto;">
            <v-spacer/>
            <v-btn
              @click="navigateBack"
              depressed
            >
              Cancel
            </v-btn>
            <v-btn
              @click="clickSubmit()"
              :loading="submitVotesLoading"
              :disabled="noVotesMade"
              color="primary"
            >
              Submit
            </v-btn>
          </v-card-actions>

        </v-container>

        <!-- END Page Actions -->

      </div>
      <!-- End Ballot Form -->

      <!-- Warning Modal -->
      <app-modal
        :value="warningModal"
        header-text="Confirm Submission"
        :persistent="true"
        width="600px"
      >
        <template #subheader>
          <v-alert
            type="warning"
            text
          >
            Not all votes have been cast! Submit without casting all available votes?
          </v-alert>
        </template>

        <template #content>
          <div style="margin: -32px -8px;"></div>
        </template>

        <template #action>
          <v-spacer/>
          <v-btn
            @click="clickReview()"
            depressed
          >
            Review
          </v-btn>
          <v-btn
            @click="submitVotes()"
            class="ml-2"
            color="primary"
            depressed
          >
            Confirm
          </v-btn>
        </template>
      </app-modal>

    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppModal from "@/components/app/AppModal"

import Controllers from "@/data/controllers"
import Identity from "@/app/Identity"

import draggable from "vuedraggable";
import moment from "moment"

export default {
  name: "Vote",

  components: {
    AppModal,
    draggable,
    ViewCardTemplate,
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    sortAlphabetical: 'asc', // 'asc' or 'desc
    sortSchool: 'teamName', // 'lastName' or 'teamName'
    loaded: false,
    submitVotesLoading: false,
    
    ballot: null,

    tables: [],
    rankVotesNumberOfTeams: 4,
    rankVotesTeam: true,
    dragging: false,
    doubleNomination: true,

    headers: [
      {
        text: 'Nominee',
        align: 'start',
        sortable: false,
        value: 'firstName',
        class: 'nominee-header',
        cellClass: 'nominee-cell'
      },
      {
        text: "Supporting Info",
        sortable: false,
        value: 'data-table-expand'
      },
    ],

    warningModal: false,
    inReview: false,

  }),

  computed: {
    noVotesMade() {
      let voteCount = 0
      this.tables.forEach(table => {
        voteCount += table.votes.length
      })

      return voteCount == 0
    },

    allVotesMade() {
      let voteCount = 0
      let votesAvailable = 0
      this.tables.forEach(table => {
        voteCount += table.votes.length
        votesAvailable += table.award.numVotesAllowed
      })

      return voteCount == votesAvailable
    }
  },

  methods: {
    tableNominationsSorted( nominations, sortBy, sortDirection ) {
        let nominationsAlphabetic = [...nominations].sort((a, b) => {
          if ( typeof (+a.lastName) === 'number' && typeof (+b.lastName) === 'number' ) {
            if (a.firstName < b.firstName) return -1
            if (a.firstName > b.firstName) return 1
          }
          if (a.lastName < b.lastName) return -1
          if (a.lastName > b.lastName) return 1
          return 0
        })

      return [...nominationsAlphabetic].sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === 'asc' ? -1 : 1
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === 'asc' ? 1 : -1
        }
        return 0
      })
    },

    updateStatHeadersBasedOnFiltering(table) {
      if (table.originalStatHeaders == undefined) table.originalStatHeaders = table.statHeaders
      table.statHeaders = table.originalStatHeaders.filter(statHeader => table.statGroupsToShow.indexOf(statHeader.stat.statGroupName) !== -1)

      if (this.ballot.ballotType == 3)
        table.nominations.forEach(nomination => {
          if (nomination.originalWeeklyStatHeaders == undefined) nomination.originalWeeklyStatHeaders = nomination.weeklyStatHeaders
          nomination.weeklyStatHeaders = nomination.originalWeeklyStatHeaders.filter(statHeader => table.statGroupsToShow.indexOf(statHeader.stat.statGroupName) !== -1)
        })

      // Table structure specific to non-weekly awards.
      if(this.ballot.ballotType != 3) {
        if (table.statHeaders.length > 0) {
          if (table.keyHeaders.length < 3)
            table.keyHeaders.push({
              text: "", // (conf) tag
              align: "start",
              width: "10px",
              sortable: false,
            })
        }
        else table.keyHeaders.pop()
      }
    },

    navigateBack() {
      this.$router.push({ name: "Voting" })
    },

    formatDate(date) {
      return moment(new Date(date)).format("M/D/YYYY")
    },

    canVote(nomination) {
      if (this.ballot.ballotType == 4){
        return this.ballot.allowVotesForOwnTeam || nomination.teamId != Identity.GetUser().teamId || nomination.firstName != Identity.GetUser().school
      } else
        return this.ballot.allowVotesForOwnTeam || nomination.teamId != Identity.GetUser().teamId
    },
    countVotes(table) {
      return table.tableVoteRankTeam.reduce((count, subArray) => count + subArray.length, 0)
    },
    addVote(vote, table) {
      if(this.canVote(vote) ) {
        if(this.rankVotesTeam ) {
          const votesCounter = table.tableVoteRankTeam.reduce((count, subArray) => count + subArray.length, 0);
          const hasVoted = table.tableVoteRankTeam.some( table => 
            table.some(obj => JSON.stringify(obj) === JSON.stringify(vote))
          )
          if(!hasVoted && table.award.numVotesAllowed - votesCounter > 0) {
            table.tableVoteRankTeam[0].push(vote)
            table.votes.push(vote)
          }

        } else if(!this.rankVotesTeam && table.votes.indexOf(vote) == -1  && table.award.numVotesAllowed - table.votes.length > 0) {
          table.votes.push(vote)
        }
      }
    },

    removeVote(vote, table, tableIndex = 0) {
      if(this.rankVotesTeam ) {
        table.tableVoteRankTeam[tableIndex].splice(table.tableVoteRankTeam[tableIndex].indexOf(vote), 1)
        table.votes.splice(table.votes.indexOf(vote), 1)
      } else
        table.votes.splice(table.votes.indexOf(vote), 1)
    },

    async clickSubmit() {
      // If all of the available votes are used, then submit as normal.
      // Else, kick the decision to the warning modal.
      if(this.allVotesMade) {
        await this.submitVotes()
      }
      else {
        this.warningModal = true
      }
    },

    clickReview() {
      this.warningModal = false
      this.inReview = true
    },

    async submitVotes() {
      if(this.submitVotesLoading) return
      
      this.warningModal = false
      this.submitVotesLoading = true

      const results = []
      this.tables.forEach(table => {
        if (this.rankVotesTeam) { 
          let votes = []
          table.tableVoteRankTeam.forEach((tbl, index) => {
            votes = [...votes, ...tbl.map(t => {
              return {
                id: 0,
                nominationId: t.id,
                resultId: 0,
                rank: index,
              }
            })
          ]
          })
          results.push({
            id: 0,
            voterId: 0,
            awardId: table.award.id,
            votes: votes
          })
        } else {
          results.push({
            id: 0,
            voterId: 0,
            awardId: table.award.id,
            votes: table.votes.map((nomination, index) => {
              return {
                id: 0,
                nominationId: nomination.id,
                resultId: 0,
                rank: index,
              }
            })
          })
        }
      })

      const res = await Controllers.ResultController.CreateResults(results)
      if(res && !res.hasError && res.data) {
        this.$root.showSnackbar('Votes cast successfully!', 'success', 5000)
        this.$router.push({ name: "Voting" })
      }
      else this.$root.showSnackbar('Failed to cast votes.', 'error', 5000)

      this.submitVotesLoading = false
    },

    async fetchTableStats(table) {
      // Fetch stats for each nomination. Set nomination.hasStats to false in case of error.
      // Doing this outside of the forEach so that all of the requests go out at once.
      if (!table.hasStatsFetched && !table.isFetchingStats) {
        table.isFetchingStats = true
        
        await Promise.all(
          table.award.nominations
            .filter(n => n.hasStats)
            .map(n => {
              if(this.ballot.ballotType != 3) {
                // Not a weekly award, get totals for overall and conference.
                return [
                  Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '1'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.conferenceTotals = gameStats.games.find(gs => gs.opponent == "Totals")
                        n.hasConferenceTotals = true
                      }
                      catch {
                        n.hasConferenceTotals = false
                      }
                    }
                    else {
                      n.hasConferenceTotals = false
                    }
                  }),
                  Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '0'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.overallTotals = gameStats.games.find(gs => gs.opponent == "Totals")
                        n.hasOverallTotals = true
                      }
                      catch {
                        n.hasOverallTotals = false
                      }
                    }
                    else {
                      n.hasOverallTotals = false
                    }
                  })
                ]
              }
              else {
                // A weekly award, get game stats.
                // TODO: season probably doesn't map 1:1 onto year.
                return Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '0'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.games = gameStats.games.filter(gs => gs.opponent != "Totals")
                        n.totals = gameStats.games.find(gs => gs.opponent == "Totals")
                      }
                      catch (e) {
                        n.hasStats = false
                      }
                    }
                    else {
                      n.hasStats = false
                    }
                  })
              }
            })
            .flat() // Flat is important for non-weekly awards.
        )

        table.isFetchingStats = false
        table.hasStatsFetched = true
      }
    }
  },

  async created() {
    this.$root.updateTitle("Vote")

    let ballotRes
    let statMasksRes
    await Promise.all([
      Controllers.BallotController.GetBallot(this.ballotId).then(_ => ballotRes = _),
      Controllers.StatMaskController.ListStatMasks().then(_ => statMasksRes = _)
    ])

    if(ballotRes && !ballotRes.hasError && statMasksRes && !statMasksRes.hasError) {
      const ballot = ballotRes.data
      const maskedStatIdSet = new Set(statMasksRes.data.map(sm => sm.statId))
      const sports = await this.$store.getters.conferenceSports
      this.rankVotesTeam = ballot.rankVotesTeam
      this.rankVotesNumberOfTeams = ballot.rankVotesNumberOfTeams
      ballot.sportShortName = sports.find(s => s.globalSportId == ballot.globalSportId).shortName

      ballot.awards.forEach(a => {
        a.statGroups.forEach(sg => {
          // Remove masked stats and make the mapping easier to work with.
          sg.stats = sg.stats.filter(stat => !maskedStatIdSet.has(stat.id))
          sg.stats.forEach(stat => {
            stat.mappingGroupName = stat.mapping.split("|")[0]
            stat.mappingStatName = stat.mapping.split("|")[1]
            stat.statGroupName = sg.name
          })
        })

        a.nominations.forEach(n => n.hasStats = (n.apiPlayerName != null && n.apiPlayerName.length > 0))

        // Package up supporting info for ease of presentation later.
        a.nominations.forEach(n => {
          n.packagedSupportingInfoItems = [
            {
              label: "Jersey",
              value: n.jersey,
            },
            {
              label: "Position",
              value: n.position,
            },
            {
              label: "Class year",
              value: n.classYear,
            },
            {
              label: "Hometown",
              value: n.hometown,
            },
            {
              label: "High School",
              value: n.highSchool,
            },
            {
              label: ballot.customFieldLabel,
              value: ballot.useCustomField ? n.customField : null,
            }
          ]
          .filter(i => i.value != null && i.value.length > 0)
        })
      })

      // Table structure shared between weekly and non-weekly awards.
      const tables = ballot.awards.map(award => {
        let statGroupsToShow

        if (ballot.confOnly && ballot.ballotType != 3) {
          statGroupsToShow = [...award.statGroups.map(sg => sg.name), ...['confOnly']]
        } else {
          statGroupsToShow = award.statGroups.map(sg => sg.name)
        }
        return {
          keyHeaders: [
            {
              text: 'Nominee',
              align: 'start',
              width: "200px",
              sortable: false,
              value: 'firstName',
              class: 'nominee-header',
            },
          ],
          award: {
            ...award
          },
          statHeaders:
            award.statGroups
              .map(sg => sg.stats)
              .flat()
              .map(stat => {
                return {
                  text: stat.abbreviation,
                  value: `${ballot.ballotType == 3 ? 'totals' : 'overallTotals'}.${stat.mappingGroupName}.${stat.mappingStatName}`,
                  width: "40px",
                  stat,
                }}),
          statGroupsToShow: statGroupsToShow,
          nominations: award.nominations,
          votes: award.results.length == 0
            ? []
            // When User is recasting, we need to map in the votes to local state.
            // It's weird, but in table state, the votes are just references to the nominations.
            : award.results[0].votes
              .map(vote => award.nominations.find(nomination => nomination.id == vote.nominationId))
              .filter(nomination => nomination != undefined), // Filter in case they voted for a nomination that was later deleted.
          expanded: [],
          hasStatsFetched: false,
          isFetchingStats: false,
        }
      })

      tables.forEach(table => {
        if (this.rankVotesTeam) { 
          table.tableVoteRankTeam = []
          for (let i = 0; i < this.rankVotesNumberOfTeams; i++) {
            table.tableVoteRankTeam[i] = JSON.parse(JSON.stringify(table.votes.filter(f => {
              return f.votes.some(v => v.rank == i)
            })))
          }
          const ranks = Array.from({ length: this.rankVotesNumberOfTeams }, (_, index) => index)
          const noRank = JSON.parse(JSON.stringify(table.votes.filter(f => {
            return f.votes.some(v => !ranks.includes(v.rank))
          })))
          table.tableVoteRankTeam[this.rankVotesNumberOfTeams-1] = JSON.parse(JSON.stringify(
            [...table.tableVoteRankTeam[this.rankVotesNumberOfTeams-1], ...noRank]
          ))
        }
        
        if (table.award.awardType.title != 'Preseason Poll') {
          table.keyHeaders.push({
            text: 'Supporting Info',
            width: "100px",
            sortable: false,
            value: 'data-table-expand'
          })  
        }
      })

      // Table structure specific to non-weekly awards.
      if(ballot.ballotType != 3) {
        tables.forEach(table => {
          table.keyHeaders.push({
            text: "", // (conf) tag
            align: "start",
            width: "10px",
            sortable: false,
          })
        })
      }

      // Table structure specific to weekly awards.
      if(ballot.ballotType == 3) {
        tables.forEach(table => {
          table.nominations.forEach(nomination => {
            nomination.weeklyKeyHeaders = [
              {
                text: 'Opponent',
                width: "150px",
                value: 'opponent'
              },
              {
                text: 'Date',
                width: "100px",
                value: 'date',
              },
              {
                text: 'Result',
                width: "100px",
                value: 'result',
              },
            ]
            nomination.weeklyStatHeaders = 
              table.award.statGroups
                .map(sg => sg.stats)
                .flat()
                .map(stat => {
                  return {
                    text: stat.abbreviation,
                    value: `totals.${stat.mappingGroupName}.${stat.mappingStatName}`,
                    width: "40px",
                    stat,
                  }})
          })
        })
      }

      if(ballot.showNominationExpands) {
        tables.forEach(table => {
          table.expanded = table.nominations.map(_ => _)
        })
      }

      this.tables = tables
      this.ballot = ballot
    }
    else {
      // Error handling
    }

    this.loaded = true
  }
}
</script>

<style lang="scss">
.table-with-padded-stats {
  th {
    padding: 0px 24px !important;
  }
  tr td {
    padding: 0px 24px !important;
  }
}

.table-with-padded-stats thead .nominee-header:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 thin 0 0;
}

.table-with-padded-stats .nominee-cell:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 thin 0 0;
}

.panel-with-no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
.bordered {
  border: aliceblue 2px solid;
  min-height: 30px;
}
</style>