<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      <v-row
        no-gutters
      >
        <v-col>
          {{ ballot && ballot.title ? `Ballot for "${ballot.title}"` : "Loading.." }}
        </v-col>
        <v-col align="right">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="printPdf"
                icon
              >
                <v-icon   
                  color="blue-grey darken-1"
                >
                mdi-cloud-download
                </v-icon>
              </v-btn>
            </template>
            Download PDF
          </v-tooltip>
        </v-col>
        
      </v-row>
    </template>
    
    <template #cardBody>
      <!-- START Ballot Form -->
      <div
        class="d-flex flex-column"
      >
        <v-container
          class="px-6 py-4 d-flex flex-column justify-space-between"
          style="height: 100%"
        >

          <!-- Voting Page Description -->
          <div
            v-if="ballot && ballot.votingPageDescription && ballot.votingPageDescription.length"
            class="d-flex flex-column mb-4"
          >
            <h4>Description</h4>
            <div class="mb-2">
              <div v-html="ballot.votingPageDescription"></div>
            </div>
          </div>          


          <!-- Sorting starts HERE -->
          <v-divider/>
          <v-row no-gutters class="mb-2 mt-4"> 
            <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <div class="d-flex align-center">
            <div class="bold">Nominees {{sortAlphabetical === 'asc' ? 'ascending' : 'descending'}} by {{ sortSchool === 'teamName' ? 'school' : 'last name' }} </div>  
            </div></div>           
            <v-spacer/>
            <v-col cols="auto" class="mr-4">
              <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <div class="d-flex align-center">
                  <div class="bold">Sort by: </div>
                </div>
              </div>
            </v-col>
            <v-col cols="auto" class="mr-4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="sortAlphabetical === 'asc' ? sortAlphabetical = 'desc' : sortAlphabetical = 'asc'"
                    icon
                  >
                    <v-icon   
                      color="blue-grey darken-1"
                      small
                    >
                      {{ sortAlphabetical === 'asc' ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending ' }}
                    </v-icon>
                  </v-btn>
                </template>
                sorting ASC or DESC
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="sortSchool === 'lastName' ? sortSchool = 'teamName' : sortSchool = 'lastName'"
                    icon
                  >
                    <v-icon   
                      color="blue-grey darken-1"
                      small
                    >
                      {{ sortSchool === 'lastName' ? 'mdi-group' : 'mdi-ungroup' }}
                    </v-icon>
                  </v-btn>
                </template>
                Sorting by school or last name
              </v-tooltip>    
            </v-col>
          </v-row> 
          <!-- Sorting ends HERE --> 

          <!-- START Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && ballot.awards.length"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(table, index) in tables"
              :key="index"
            >
              <v-expansion-panel-header
                @click="fetchTableStats(table)"
              >
                <div class="d-flex justify-space-between">                
                  <h4>
                    {{ table.award.awardType.title != 'Preseason Poll' ? table.award.awardType.title : ballot.title }}
                  </h4>
                </div>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content class="panel-with-no-padding">
                <v-skeleton-loader
                  v-if="table.isFetchingStats"
                  type="table-heading, table-heading"
                  elevation="0"
                  tile
                  height="120px"
                />
                <div
                  v-else
                >
                  <div
                    class="w-100"
                    style="background-color: #F5F5F5"
                  >
                    <v-row 
                      class="d-flex align-center px-4 py-2"
                      no-gutters
                    >
                      <v-col>
                        <v-chip-group
                          v-model="table.statGroupsToShow"
                          active-class="primary--text"
                          @change="updateStatHeadersBasedOnFiltering(table)"
                          multiple
                          column
                        >
                          <v-chip
                            v-for="statGroup in table.award.statGroups"
                            :key="statGroup.id"
                            :value="statGroup.name"
                            filter
                          >
                            {{ statGroup.name.replace(/([A-Z])/g, ' $1').trim() }}
                          </v-chip>
                          <v-chip
                            value="confOnly"
                            filter
                          >
                            Show conf only
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- START Award Nominees Table -->
                  <v-data-table
                    :headers="[table.keyHeaders, table.statHeaders].flat()"
                    :items="tableNominationsSorted(table.nominations, sortSchool, sortAlphabetical)"
                    :items-per-page="ballot.numDisplayedNominations != null ? ballot.numDisplayedNominations : 5"
                    :expanded.sync="table.expanded"
                    class="table-with-padded-stats"
                    show-expand
                    :options.sync="options"
                  > 
                    <template #item="{ item, expand, isExpanded }">
                      <tr>
                        <td class="font-weight-medium nominee-cell">
                          <div>
                            <div>
                              {{ item.firstName.split('/')[0] }} {{ item.lastName.split('/')[0] }}
                              {{ 
                                table.award.awardType.doubleNomination ?
                                `/ ${item.firstName.split('/')[1] } ${ item.lastName.split('/')[1]}` :
                                ''
                              }}
                            </div>
                            <div class="text-caption">({{ item.teamName }})</div>
                          </div>
                        </td>
                        <td
                          v-if="table.award.awardType.title != 'Preseason Poll'"
                        >
                          <v-btn
                            @click="expand(!isExpanded)"
                            icon
                          >
                            <v-icon
                              :style="{'transform': isExpanded ? 'rotate(-180deg)' : ''}"
                              style="transition: transform .3s;"
                            >
                              mdi-chevron-down
                            </v-icon>
                          </v-btn>
                        </td>
                        <!-- Marker for conference stats vs overall stats -->
                        <td
                          v-if="ballot.ballotType != 3 && table.statHeaders.length > 0"
                          class="mx-0 px-0"
                          style="font-size: 0.755rem;"
                        >
                          <div class="d-flex flex-column align-end">
                            <div style="opacity: 0;" v-show="!table.statGroupsToShow.includes('confOnly')">(overall)</div>
                            <div>(conf)</div>
                          </div>
                        </td>
                        <!-- Stats Go Here -->
                        <td
                          v-for="(statHeader, index) in table.statHeaders"
                          :key="index"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-if="item.hasStats && ballot.ballotType == 3"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{
                                  item.totals[statHeader.stat.mappingGroupName] && item.totals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                    ? item.totals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                    : "-"
                                }}
                              </div>
                              <div
                                v-else-if="item.hasStats && ballot.ballotType != 3"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="d-flex flex-column align-start">
                                  <div v-show="!table.statGroupsToShow.includes('confOnly')">
                                    {{
                                      item.hasOverallTotals && item.overallTotals[statHeader.stat.mappingGroupName] && item.overallTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        ? item.overallTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        : "-"
                                    }}
                                  </div>
                                  <div >
                                    {{
                                      item.hasConferenceTotals && item.conferenceTotals[statHeader.stat.mappingGroupName] && item.conferenceTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        ? item.conferenceTotals[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                        : "-"
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                v-bind="attrs"
                                v-on="on"
                              >
                                -
                              </div>
                            </template>
                            {{ statHeader.stat.statGroupName }} | {{ statHeader.stat.name}}
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expanded-item="{ item, headers }">
                      <td
                        :colspan="headers.length"
                        style="background-color: #ffffeb;"
                      >
                        <div
                          class="d-flex"
                          style="font-size: 0.775rem; width: min(1024px, 80vw); position: sticky; left: 0; z-index: 2;"
                        >
                          <!-- Jersey, Position, Class Year, Hometown, High School  -->
                          <div
                            class="d-flex flex-row mt-4 mx-6"
                          >
                            <div
                              v-for="(infoItem, index) in item.packagedSupportingInfoItems"
                              :key="index"
                              class="d-flex align-center"
                            >
                                <span style="font-weight: bolder;">{{infoItem.label}}:&nbsp;</span>{{infoItem.value}}
                                <v-divider v-if="index < item.packagedSupportingInfoItems.length - 1" class="mx-2" vertical/>
                            </div>
                          </div>
                        </div>
                        <div
                          style="width: min(1024px, 80vw); position: sticky; left: 0; z-index: 2;"
                        >
                          <div
                            v-html="item.supportingInformation"
                            class="mt-2 mx-6"
                          ></div>
                        </div>
                        <v-data-table
                          v-if="ballot.ballotType == 3 && item.hasStats"
                          :headers="[item.weeklyKeyHeaders, item.weeklyStatHeaders].flat()"
                          :items="item.games"
                          :items-per-page="-1"
                          style="background-color: #ffffeb;"
                          hide-default-footer
                          dense
                          flat
                        >
                          <template #item="itemProps">
                            <tr>
                              <td>{{ itemProps.item.at_vs }} {{ itemProps.item.opponent }}</td>
                              <td>{{ formatDate(itemProps.item.date) }}</td>
                              <td>{{ `${itemProps.item.result} ${itemProps.item.score ? itemProps.item.score : ""}` }}</td>
                              <!-- Stats Go Here -->
                              <td
                                v-for="(statHeader, index) in item.weeklyStatHeaders"
                                :key="index"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{on, attrs }">
                                    <div
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ 
                                        itemProps.item[statHeader.stat.mappingGroupName]
                                        && itemProps.item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                          ? itemProps.item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                          : "-"
                                      }}
                                    </div>
                                  </template>
                                  {{ statHeader.stat.statGroupName }} | {{ statHeader.stat.name}}
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <v-divider/>
                      </td>
                    </template>

                  </v-data-table>
                  <!-- END Award Nominees Table -->
                </div>

              </v-expansion-panel-content>
              
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Expansion Panels -->

          <!-- Loading stuff goes here? -->
          <div v-else></div>

          <!-- START Page Actions -->
          <v-card-actions style="margin-top: auto;">
            <v-spacer/>
            <v-btn
              @click="navigateBack"
              depressed
            >
              Back
            </v-btn>
          </v-card-actions>

</v-container>

<!-- END Page Actions -->

</div>
<!-- End Ballot Form -->

</template>
</view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

import moment from 'moment-timezone'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
export default {
  name: "ViewBallot",

  components: {
    ViewCardTemplate,
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    sortAlphabetical: 'asc', // 'asc' or 'desc
    sortSchool: 'teamName', // 'lastName' or 'teamName'
    loaded: false,    
    ballot: null,
    tables: [],
    headers: [
      {
        text: 'Nominee',
        align: 'start',
        sortable: false,
        value: 'firstName',
      },
      {
        text: "Supporting Info",
        sortable: false,
        value: 'data-table-expand'
      },
    ],
    options: {
        sortBy: [],
        sortDesc: []
      }
  }),
  
  methods: {
    async printPdf() {
      let doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'legal',
        margin: 1,
        putOnlyUsedFonts: true
      })
      const rowStyle = {
        valign: 'middle',
        halign: 'center',
        fontSize: 7,
      }
      
      this.tables.forEach(async (t) => {
        await this.fetchTableStats(t)
      })
      await this.tables.forEach((t, tIndex) => {
        this.fetchTableStats(t).then(() => {
          // const rowSpan = t.statGroupsToShow.includes('confOnly') ? 1 : 2

          let body = []
          let nestedBody = []
          

          const rows = this.tableNominationsSorted(t.nominations, this.sortSchool, this.sortAlphabetical)
          const header = [t.keyHeaders, t.statHeaders].flat()

          header.splice(1, 1)
          const columnsHeaders = []

          header.forEach((column, idx) => {
            if(idx <= 40) {

              columnsHeaders.push({
                content: column.text,
                styles: {
                  halign: 'center',
                  fontSize: 6,
                  maxCellWidth: '20',
                  fillColor: [128, 128, 128],
                  lineColor: 'white',
                  textColor: 'white',
                  fontStyle: 'bold'
                },
              })
            }
          })
          if (header.length == 1) {
            columnsHeaders.push({
              styles: {
                halign: 'center',
                fontSize: 7,
                fillColor: [128, 128, 128],
                lineColor: 'white',
                textColor: 'white',
                fontStyle: 'bold'
              },
            })
          }
          // const headerKeys = header.map(h => h.text)
          let nestedHeader = []

          rows.forEach((r, rIndex) => {
            let row = []
            let rowField = ''
            if (this.ballot.ballotType != 3) {
              if (!t.statGroupsToShow.includes('confOnly')) {
                rowField = '\n'
              }
              rowField += "(conf)"
              row.push({
                content: rowField,
                styles: rowStyle,
              })
            }
            t.statHeaders.forEach((st, stIdx) => {
              if(stIdx <= 40) {
                if (r.hasStats && this.ballot.ballotType == 3) {
                  row.push({
                    content: r.totals[st.stat.mappingGroupName] && r.totals[st.stat.mappingGroupName][st.stat.mappingStatName] ?
                      r.totals[st.stat.mappingGroupName][st.stat.mappingStatName] :
                      "-",
                    styles: rowStyle,

                  })
                } else if (r.hasStats && this.ballot.ballotType != 3) {
                  let rowField = ''
                  if (!t.statGroupsToShow.includes('confOnly')) {
                    rowField = r.hasOverallTotals && r.overallTotals[st.stat.mappingGroupName] && r.overallTotals[st.stat.mappingGroupName][st.stat
                        .mappingStatName
                      ] ?
                      r.overallTotals[st.stat.mappingGroupName][st.stat.mappingStatName] :
                      "-";
                    rowField += '\n'
                  }
                  rowField += r.hasConferenceTotals && r.conferenceTotals[st.stat.mappingGroupName] && r.conferenceTotals[st.stat.mappingGroupName][st.stat.mappingStatName] ?
                    r.conferenceTotals[st.stat.mappingGroupName][st.stat.mappingStatName] :
                    "-"
                  row.push({
                    content: rowField,
                    styles: rowStyle
                  })
                } else {
                  row.push('-')
                }
              }
            })
            // if (t.statGroupsToShow.includes('confOnly')){

            if (header.length > 1){
              const fName = r.firstName.split('/')
              const lName = r.lastName.split('/')
              let fullName = `${fName[0] } ${lName[0]}`
              if (t.award.awardType.doubleNomination ){
                fullName += ` /  ${fName[1]} ${lName[1]}`
              }
              row.unshift({
                dataKey: 'nominee',
                rowSpan: 2,
                content: `${fullName}\n(${r.teamName})`,
                styles: rowStyle,
              })
              
              body.push(row)
              row = []
            }
            rowField = ''
            r.packagedSupportingInfoItems.forEach((info, idx) => {
              rowField +=
                `${info.label}: ${info.value}${idx < r.packagedSupportingInfoItems.length - 1 ? ', ' : ''}`
            })
            let html = r.supportingInformation

           
              rowField += '\n'
              html = html.replace(/<\/div>/ig, '');
              html = html.replace(/<\/li>/ig, '\n');
              html = html.replace(/<li>/ig, '  *  ');
              html = html.replace(/<\/ul>/ig, '\n');
              html = html.replace(/<\/p>/ig, '');
              html = html.replace(/<p>/ig, '');
              html = html.replace(/<[^>]+>/ig, '');
              html = html.replace(/&nbsp;/ig, ' ');
              rowField += html;
            if (header.length == 1){
              const fName = r.firstName.split('/')
              const lName = r.lastName.split('/')
              let fullName = `${fName[0] } ${lName[0]}`
              if (t.award.awardType.doubleNomination ){ 
                fullName += ` /  ${fName[1]} ${lName[1]}`
              }
              row.unshift({
                rowSpan: 1,
                content: `${fullName}\n(${r.teamName})`,
                styles: rowStyle,
              })
              row.push({
                content: rowField.trim().length == 0 ? 'No info' : rowField,
                styles: {
                  halign: 'left',
                  fontSize: 8
                },
                colSpan: t.statHeaders.length == 0 ? 1 : t.statHeaders.length + 2
              })
              body.push(row)
            } else {
              row.push({
                content: rowField.trim().length == 0 ? 'No info' : rowField,
                styles: {
                  halign: 'left',
                  fontSize: 8
                },
                colSpan: t.statHeaders.length == 0 ? 1 : t.statHeaders.length + 2
              })
              body.push(row)
            }
            if (this.ballot.ballotType == 3 && r.hasStats) {
              let nestedTableCell = [{
                content: 'customStats',
                colSpan: t.statHeaders.length + 3,
                // Dynamic height of nested tables are not supported right now
                // so we need to define height of the parent cell
                styles: {
                  halign: 'center',
                  fontSize: 8,
                  minCellHeight: (r.games.length + 1) * 6 + 8
                },
              }]
              body.push(nestedTableCell)
              const fontSizeInner = [r.weeklyKeyHeaders, r.weeklyStatHeaders].flat().length > 20 ? 6 : 8
              const fontSizeInnerRow = [r.weeklyKeyHeaders, r.weeklyStatHeaders].flat().length > 20 ? 7 : 7
              nestedHeader = [r.weeklyKeyHeaders, r.weeklyStatHeaders].flat().slice(0, 41).map(h => {
                return {
                  content: h.text,
                  styles: {
                    halign: 'center',
                    fontSize: fontSizeInner,
                    fillColor: [192,192,192],
                    lineColor: 'white',
                    textColor: 'white',
                    fontStyle: 'bold',
                    minCellHeight: 5,
                    maxCellHight: 5

                  }
                }
              })
              const nestedGames = []
              const rowStyleInner = {
                valign: 'middle',
                halign: 'center',
                fontSize: fontSizeInnerRow,
                minCellHeight: 5,
                maxCellHight: 5
              }
              r.games.forEach(g => {
                row = []
                row.push({
                  content: g.opponent,
                  styles: rowStyleInner,
                })
                row.push({
                  content: g.date,
                  styles: rowStyleInner,
                })
                row.push({
                  content: `${g.result} ${g.score ? g.score : ""}`,
                  styles: rowStyleInner,
                })
                r.weeklyStatHeaders.forEach((st, stIdx) => {
                  if (stIdx <=37) {
                    const gameInfo = g[st.stat.mappingGroupName] &&
                      g[st.stat.mappingGroupName][st.stat.mappingStatName] ?
                      g[st.stat.mappingGroupName][st.stat.mappingStatName] :
                      "-"
                    let gameObj = {}
                    gameObj[st.value] = gameInfo
                    row.push({
                      content: gameInfo,
                      styles: rowStyleInner,
                    })
                  }
                 
                })
                nestedGames.push(row)
              })
              nestedBody.push(nestedGames)
            }
            if (rows.length - 1 > rIndex)
              body.push(columnsHeaders)
          })
          doc.text(t.award.awardType.title, 14, 10)
          let i = 0
          autoTable(doc, {
            startY: 15,
            head: [
              columnsHeaders
            ],
            columnStyles: {
              id: {
                halign: 'center'
              },
            },
            theme: 'grid',
            body: body,
            rowPageBreak: true,
            horizontalPageBreak: columnsHeaders.length <= 10 ? false : true,
            didDrawCell: function(data) {
              if (data.cell.text[0] == 'customStats' && data.row.section === 'body') {
                autoTable(doc, {
                  startY: data.cell.y + 1,
                  margin: {
                    left: data.cell.x + 1
                  },
                  tableWidth: data.cell.width - 4,
                  theme: 'grid',
                  styles: {
                    // minCellHeight: 6,
                    halign: 'center',
                    valign: 'middle',
                    fontSize: 6,
                  },
                  head: [nestedHeader],
                  body: nestedBody[i],
                })
                i++
              }
            },
          })
          if (this.tables.length - 1 > tIndex)
            doc.addPage('legal', 'landscape')

        })
      })
      doc.save(`Ballot for ${this.ballot.title} ${new Date().toLocaleString()}`)

    },
    tableNominationsSorted( nominations, sortBy, sortDirection ) {
      let nominationsAlphabetic = [...nominations].sort((a, b) => {
        if (a.lastName < b.lastName) return -1
        if (a.lastName > b.lastName) return 1
        return 0
      })
      
      return [...nominationsAlphabetic].sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === 'asc' ? -1 : 1
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === 'asc' ? 1 : -1
        }
        return 0
      })
    },
    
    updateStatHeadersBasedOnFiltering(table) {
      if (table.originalStatHeaders == undefined) table.originalStatHeaders = table.statHeaders
      table.statHeaders = table.originalStatHeaders.filter(statHeader => table.statGroupsToShow.indexOf(statHeader.stat.statGroupName) !== -1)
      
      if (this.ballot.ballotType == 3)
      table.nominations.forEach(nomination => {
        if (nomination.originalWeeklyStatHeaders == undefined) nomination.originalWeeklyStatHeaders = nomination.weeklyStatHeaders
        nomination.weeklyStatHeaders = nomination.originalWeeklyStatHeaders.filter(statHeader => table.statGroupsToShow.indexOf(statHeader.stat.statGroupName) !== -1)
      })
      
      // Table structure specific to non-weekly awards.
      if(this.ballot.ballotType != 3) {
        if (table.statHeaders.length > 0) {
          if (table.keyHeaders.length < 3)
            table.keyHeaders.push({
              text: "", // (conf) tag
              align: "start",
              width: "10px",
              sortable: false,
            })
        }
        else table.keyHeaders.pop()
      }
    },
    
    navigateBack() {
      this.$router.go(-1)
    },
    
    formatDate(date) {
      return moment(new Date(date)).format("M/D/YYYY")
    },
    
    async fetchTableStats(table) {
      // Fetch stats for each nomination. Set nomination.hasStats to false in case of error.
      // Doing this outside of the forEach so that all of the requests go out at once.
      if (!table.hasStatsFetched && !table.isFetchingStats) {
        table.isFetchingStats = true
        
        await Promise.all(
          table.award.nominations
            .filter(n => n.hasStats)
            .map(n => {
              if(this.ballot.ballotType != 3) {
                // Not a weekly award, get totals for overall and conference.
                return [
                  Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '1'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.conferenceTotals = gameStats.games.find(gs => gs.opponent == "Totals")
                        n.hasConferenceTotals = true
                      }
                      catch {
                        n.hasConferenceTotals = false
                      }
                    }
                    else {
                      n.hasConferenceTotals = false
                    }
                  }),
                  Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '0'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.overallTotals = gameStats.games.find(gs => gs.opponent == "Totals")
                        n.hasOverallTotals = true
                      }
                      catch {
                        n.hasOverallTotals = false
                      }
                    }
                    else {
                      n.hasOverallTotals = false
                    }
                  })
                ]
              }
              else {
                // A weekly award, get game stats.
                // TODO: season probably doesn't map 1:1 onto year.
                return Controllers.ConferenceController
                  .GetPlayerGameStats(
                    encodeURI(n.apiPlayerName),
                    n.teamId,
                    this.ballot.sportShortName,
                    this.ballot.season,
                    this.ballot.gamesOccuringBetweenStartDate,
                    this.ballot.gamesOccuringBetweenEndDate,
                    '0'
                  )
                  .then(res => {
                    if(res && !res.hasError) {
                      try {
                        const gameStats = JSON.parse(res.data)
                        n.games = gameStats.games.filter(gs => gs.opponent != "Totals")
                        n.totals = gameStats.games.find(gs => gs.opponent == "Totals")
                      }
                      catch (e) {
                        n.hasStats = false
                      }
                    }
                    else {
                      n.hasStats = false
                    }
                  })
              }
            })
            .flat() // Flat is important for non-weekly awards.
        )
        
        table.isFetchingStats = false
        table.hasStatsFetched = true
      }
    }
  },
  watch: {
    options: {
      handler(newOptions) {
        const { sortBy, sortDesc } = newOptions;
        // Check if we have a column to sort by and it's a new column
        if (sortBy.length && sortDesc.length && sortDesc[0] === false) {
          // Set the sort order to descending for the first click
          this.options.sortDesc = [true];
        }
      },
      deep: true
    },
    tables: {
      deep: true,
      handler(val) {
        val.map(table => {
          if (table.statGroupsToShow.includes('confOnly')) {
              table.statHeaders.map(kh => {
                if(kh.value.includes('overallTotals'))
                  this.$set(kh, 'value', kh.value.replace('overallTotals', 'conferenceTotals'))
                return kh
              })
          } else {
            table.statHeaders.map(kh => {
                if(kh.value.includes('conferenceTotals'))
                  this.$set(kh, 'value', kh.value.replace( 'conferenceTotals', 'overallTotals'))
                return kh
              })
          }
          return table
        })
      }
    }
  },
  async created() {
    this.$root.updateTitle("View Ballot")
    
    let ballotRes
    let statMasksRes
    await Promise.all([
    Controllers.BallotController.AdminGetBallot(this.ballotId).then(_ => ballotRes = _),
    Controllers.StatMaskController.ListStatMasks().then(_ => statMasksRes = _)
    ])
    
    if(ballotRes && !ballotRes.hasError && statMasksRes && !statMasksRes.hasError) {
      const ballot = ballotRes.data
      const maskedStatIdSet = new Set(statMasksRes.data.map(sm => sm.statId))
      const sports = await this.$store.getters.conferenceSports
      ballot.sportShortName = sports.find(s => s.globalSportId == ballot.globalSportId).shortName
      
      ballot.awards.forEach(a => {
        a.statGroups.forEach(sg => {
          // Remove masked stats and make the mapping easier to work with.
          sg.stats = sg.stats.filter(stat => !maskedStatIdSet.has(stat.id))
          sg.stats.forEach(stat => {
            stat.mappingGroupName = stat.mapping.split("|")[0]
            stat.mappingStatName = stat.mapping.split("|")[1]
            stat.statGroupName = sg.name
          })
        })
        
        a.nominations.forEach(n => n.hasStats = (n.apiPlayerName != null && n.apiPlayerName.length > 0))
        
        // Package up supporting info for ease of presentation later.
        a.nominations.forEach(n => {
          n.packagedSupportingInfoItems = [
          {
            label: "Jersey",
            value: n.jersey,
          },
          {
            label: "Position",
            value: n.position,
          },
          {
            label: "Class year",
            value: n.classYear,
          },
          {
            label: "Hometown",
            value: n.hometown,
          },
          {
            label: "High School",
            value: n.highSchool,
          },
          {
            label: ballot.customFieldLabel,
            value: ballot.useCustomField ? n.customField : null,
          }
          ]
          .filter(i => i.value != null && i.value.length > 0)
        })
      })
      
      // Table structure shared between weekly and non-weekly awards.
      const tables = ballot.awards.map(award => {
        let statGroupsToShow
        if (ballot.confOnly && ballot.ballotType != 3) {
          statGroupsToShow = [...award.statGroups.map(sg => sg.name), ...['confOnly']]
        } else {
          statGroupsToShow = award.statGroups.map(sg => sg.name)
        }
        return {
          keyHeaders: [
          {
            text: 'Nominee',
            align: 'start',
            width: "200px",
            sortable: false,
            value: 'firstName',
            class: 'nominee-header',
          },
          ],
          award: {
            ...award
          },
          statHeaders:
          award.statGroups
          .map(sg => sg.stats)
          .flat()
          .map(stat => {
            return {
              text: stat.abbreviation,
              value: `${ballot.ballotType == 3 ? 'totals' : 'overallTotals'}.${stat.mappingGroupName}.${stat.mappingStatName}`,
              width: "40px",
              stat,
            }}),
            statGroupsToShow: statGroupsToShow,
            nominations: award.nominations,
            expanded: [],
            hasStatsFetched: false,
            isFetchingStats: false,
          }
        })
        
        tables.forEach(table => {
          if (table.award.awardType.title != 'Preseason Poll'){
            {
              table.keyHeaders.push({
              text: 'Supporting Info',
              width: "100px",
              sortable: false,
              value: 'data-table-expand'
              })
            }
          }
        })

        // Table structure specific to non-weekly awards.
        if(ballot.ballotType != 3) {
          tables.forEach(table => {
            table.keyHeaders.push({
              text: "", // (conf) tag
              align: "start",
              width: "10px",
              sortable: false,
            })
          })
        }
        
        // Table structure specific to weekly awards.
        if(ballot.ballotType == 3) {
          tables.forEach(table => {
            table.nominations.forEach(nomination => {
              nomination.weeklyKeyHeaders = [
              {
                text: 'Opponent',
                width: "150px",
                value: 'opponent'
              },
              {
                text: 'Date',
                width: "100px",
                value: 'date',
              },
              {
                text: 'Result',
                width: "100px",
                value: 'result',
              },
              ]
              nomination.weeklyStatHeaders = 
              table.award.statGroups
              .map(sg => sg.stats)
              .flat()
              .map(stat => {
                return {
                  text: stat.abbreviation,
                  value: `totals.${stat.mappingGroupName}.${stat.mappingStatName}`,
                  width: "40px",
                  stat,
                }})
              })
            })
          }
          
          if(ballot.showNominationExpands) {
            tables.forEach(table => {
              table.expanded = table.nominations.map(_ => _)
            })
          }
          
          this.tables = tables
          this.ballot = ballot
        }
        else {
          // Error handling
        }
        
        this.loaded = true
        this.tables.forEach(async(t) => {
          await this.fetchTableStats(t)
        })
      }
      
    }
  </script>
  
  <style lang="scss">
  .table-with-padded-stats {
    th {
      padding: 0px 24px !important;
    }
    tr td {
      padding: 0px 24px !important;
    }
  }
  
  .table-with-padded-stats thead .nominee-header:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 thin 0 0;
  }
  
  .table-with-padded-stats .nominee-cell:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 thin 0 0;
  }
  .panel-with-no-padding .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>