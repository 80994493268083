<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      {{ ballot && ballot.title ? `Nomination Results for "${ballot.title}"` : "Loading.." }}
    </template>

    <template #cardBody>

      <!-- START NominationResults  -->
      <div
        class="d-flex flex-column"
        style="height: 100%"
      >
        <v-container
          class="px-6 py-4 flex-grow-1"
        >
          <div
            v-if="ballot && tables.length == 0"
            class="d-flex align-center justify-center"
            style="height: 100%; width: 100%;"
          >
            <h3>No Nominations Found</h3>
          </div>

          <!-- CSV Export Nominations -->
          <v-row
            v-if="tables.length"
            class="mb-4"
            no-gutters
          >
            <v-spacer/>
            <v-col cols="auto">
              <div class="d-flex align-center ma-2">
                <v-checkbox
                  v-model="exportWithStats"
                  label="Include Stats"
                  class="pa-0 mt-0"
                  hide-details
                />
                <v-checkbox
                  v-model="exportWithSupporting"
                  label="Include Supporting info"
                  class="pa-0 mt-0"
                  hide-details
                />
              </div>
            </v-col>
            <v-col cols="auto">
              <div>
                <v-btn
                  @click="exportNominations()"
                  :loading="exportNominationsLoading"
                  color="primary"
                  block
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-table-large
                  </v-icon>
                  Export Nominations
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- VIEW TYPES -->
          <v-row no-gutters class="mb-2" v-if="ballot && tables.length > 0">            
            <v-spacer/>
            <v-col cols="auto" class="mr-4">
              <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <div class="d-flex align-center">
                  <div class="bold">{{ viewType === 'closedTabs' ? 'View by Closed Tabs' : 'View By Bulk Delete' }}</div>
                  <!-- <div style="font-size: 0.6rem;">({{ vote.voter.school }})</div> -->
                </div>
              </div>
            </v-col>
            <v-col cols="auto">
              <div class="w-100 d-flex align-center justify-center" style="height: 100%;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="viewType = 'closedTabs'"
                      class="bg-blue-grey lighten-5"
                      icon
                    >
                      <v-icon   
                        color="blue-grey darken-1"
                      >
                        mdi-ballot
                      </v-icon>
                    </v-btn>
                  </template>
                  By Closed Tabs View
                </v-tooltip>
                <!--  -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="viewType = 'openTabs'"
                      icon
                    >
                      <v-icon   
                        color="blue-grey darken-1"
                      >
                        mdi-vote
                      </v-icon>
                    </v-btn>
                  </template>
                  By Bulk Delete View
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <!-- START Expansion Panels -->
          <v-expansion-panels
            v-if="ballot && viewType === 'closedTabs'"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(table, index) in tables"
              :key="index"
            >
              <v-expansion-panel-header class="">
                <div class="d-flex justify-space-between">
                  <h4>{{ table.teamName }}</h4>
                  <h4 class="px-4">
                    ({{ table.nominations.length }} Nominees)
                  </h4>
                </div>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content class="panel-with-no-padding">
                <!-- START Award Nominations Table -->
                <v-data-table
                  :headers="headers"
                  :items="table.nominations"
                  class="mt-2"
                  dense
                >
                  <template #item="{ item }">
                    <tr>
                      <td class="font-weight-medium"> 
                        {{ item.firstName.split('/')[0] }} {{ item.lastName.split('/')[0] }}
                        {{ 
                          item.awardType.doubleNomination ?
                          `/ ${item.firstName.split('/')[1] } ${ item.lastName.split('/')[1]}` :
                          ''
                        }}
                      </td>
                      <td>{{ item.awardType.title }}</td>
                      <!-- <td>{{ formatDate(item.nominationDate)}}</td> -->
                      <td>{{ formatDate(item.modifiedOn) }}</td>
                      <td>{{ item.nominator.firstName }} {{ item.nominator.lastName }}</td>
                      <td>
                        <div>
                          <v-btn
                            @click="openDeleteModal(item, table)"
                            color="red accent-2"
                            icon
                          >
                          <!-- ff5252 -->
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- END Award Nominations Table -->

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- END Expansion Panels -->


          <!-- START Expansion Panels -->
          <div v-if="ballot && viewType === 'openTabs'">
            <v-row
              v-if="tables.length"
              class="mb-4"
              no-gutters
            >
              <v-spacer/>
              <v-col cols="auto">
                <div>
                  <v-btn
                    @click="openDeleteModal(selectedBulkDelete, tables)"
                    :loading="exportNominationsLoading"
                    color="red accent-2"
                    block
                    :disabled="ballotsAreMarkedForDelete.length == 0"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-delete
                    </v-icon>
                    Bulk Delete
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <!-- Start the list bulk DELETE -->
            <div 
              v-for="(table, index) in tables"
              :key="index"
              class="mt-4 mb-6"
            >
  
              <div class="d-flex justify-space-between">
                <h4>{{ table.teamName }}</h4>
                <h4 class="px-4">
                  ({{ table.nominations.length }} Nominees)
                </h4>
              </div>
                
              <div class="panel-with-no-padding">
                <!-- START Award Nominations Table -->
                <v-data-table
                  :headers="headersBulkDelete"
                  :items="table.nominations"
                  :items-per-page="-1"
                  class="mt-2"
                  hide-default-footer 
                  disable-pagination
                  dense
                >
                  <template #item="{ item }">
                    <tr :class="item.markedForBatchDelete ? 'blue-grey lighten-5' : 'bg-blue-grey lighten-5'">
                      <td>
                        <div class="d-flex justify-center">
                          <v-checkbox
                            v-model="item.markedForBatchDelete"
                            class="batch-reset-checkbox ma-0 pa-0"
                            hide-details
                            dense
                          />
                          <!-- {{ item.id }} -->
                        </div>
                      </td>
                      <td class="font-weight-medium">
                        {{ item.firstName.split('/')[0] }} {{ item.lastName.split('/')[0] }}
                        {{ 
                            item.awardType.doubleNomination ?
                            `/ ${item.firstName.split('/')[1] } ${ item.lastName.split('/')[1]}` :
                            ''
                          }}
                      </td>
                      <td>{{ item.awardType.title }}</td>
                      <!-- <td>{{ formatDate(item.nominationDate)}}</td> -->
                      <td>{{ formatDate(item.modifiedOn) }}</td>
                      <td>{{ item.nominator.firstName }} {{ item.nominator.lastName }}</td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- END Award Nominations Table -->
  
              </div>
  
  
  
            </div>

          </div>       

          <!-- END Expansion Panels -->

        </v-container>

        <!-- START Page Actions -->
        <!-- TODO: consider refactoring the template s.t. the actions are within the container. -->
        <v-card-actions
          class="px-6 py-4"
          style="margin-top: auto;"
        >
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Back
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->

      </div>
      <!-- END NominationResults -->

      <!-- Nomination Deletion Modal -->
      <app-action-modal
        @cancel="deleteModal = false"
        @action="deleteNomination(nominationUpForDeletion, nominationUpForDeletionTable)"
        :value="deleteModal"
        :loading="nominationDeleteLoading"
        :header-text="
          `Delete nomination for ${!nominationUpForDeletion?.length > 0 
            ? nominationUpForDeletion != null
              ? `${nominationUpForDeletion.firstName} ${nominationUpForDeletion.lastName}`
              : ''
            : 'those selected'}`
        "
        warning-text="Are you sure you want to delete this nomination? This action cannot be undone."
      />

    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppActionModal from "@/components/app/AppActionModal.vue"

import Controllers from "@/data/controllers"
import CsvClient from "@/data/CsvClient"

import moment from 'moment-timezone'

export default {
  name: "NominationResults",

  components: {
    ViewCardTemplate,
    AppActionModal,
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    loaded: false,
    viewType: "closedTabs",

    ballot: null,

    exportWithStats: false,
    exportWithSupporting: false,
    statsNotLoaded: true,
    allStatGroupsInBallot: null,
    exportNominationsLoading: false,
    neverTrue: false,

    headers: [
      {
        text: "Nominee",
        align: "start",
        width: "25%",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Award",
        width: "25%",
        sortable: true,
        value: "awardType.title",
      },
      {
        text: "Nomination Date",
        width: "20%",
        sortable: true,
        value: "nominationDate",
      },
      {
        text: "Nominator",
        width: "20%",
        sortable: true,
        value: "nominatorName",
      },
      {
        text: "Delete",
        width: "5%",
      },
    ],

    headersBulkDelete: [
      { 
        text: "Delete", 
        sortable: false, 
        align: "center", 
        width: "5%", 
      },
      {
        text: "Nominee",
        align: "start",
        width: "30%",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Award",
        width: "25%",
        sortable: true,
        value: "awardType.title",
      },
      {
        text: "Nomination Date",
        width: "20%",
        sortable: true,
        value: "nominationDate",
      },
      {
        text: "Nominator",
        width: "20%",
        sortable: true,
        value: "nominatorName",
      }
    ],

    tables: [],
    selectedBulkDelete: [],

    deleteModal: false,
    nominationUpForDeletion: null,
    nominationUpForDeletionTable: null,
    nominationDeleteLoading: false,
  }),

  computed: {
    ballotsAreMarkedForDelete() {
      this.tables.forEach( item => {
        item.nominations.forEach(ballot => {
          let isInArray = this.selectedBulkDelete.find(el => el === ballot.id)
          if (ballot.markedForBatchDelete === true) !isInArray ? this.selectedBulkDelete.push(ballot.id) : null
          else isInArray ? this.selectedBulkDelete.splice(this.selectedBulkDelete.indexOf(ballot.id), 1): null;
        })
      })    
      return this.selectedBulkDelete;
    },
  },

  methods: {
    navigateBack() {
      this.$router.go(-1)
    },

    formatDate(date) {
      return date == null
        ? "N/A"
        : moment.utc(date).tz("America/New_York").format("MM/DD/YY hh:mm A")
    },

    async exportNominations() {
      this.exportNominationsLoading = true

      if(this.exportWithStats && this.statsNotLoaded) {
        // Need the shortname for fetching stats.
        const sports = await this.$store.getters.conferenceSports
        this.ballot.sportShortName = sports.find(s => s.globalSportId == this.ballot.globalSportId).shortName
        
        // The formatting and ease of access will be good to have later.
        const statGroupSet = new Set()
        this.ballot.awards.forEach(a => a.statGroups.forEach(sg => statGroupSet.add(sg)))
        this.allStatGroupsInBallot = Array.from(statGroupSet)

        this.ballot.awards.forEach(a => {
          a.statGroups.forEach(sg => {
            sg.stats.forEach(s => {
              s.groupMapping = s.mapping.split("|")[0]
              s.nameMapping = s.mapping.split("|")[1]
            })
          })
        })

        // Attach stats to nominations.
        const nominations = this.tables.map(t => t.nominations).flat()
        nominations.forEach(n => n.hasStats = (n.apiPlayerName != null && n.apiPlayerName.length > 0))
        await Promise.all(
          nominations
          .filter(n => n.hasStats)
          .map(n => {
            return Controllers.ConferenceController
              .GetPlayerGameStats(
                encodeURI(n.apiPlayerName),
                n.teamId,
                this.ballot.sportShortName,
                this.ballot.season,
                this.ballot.gamesOccuringBetweenStartDate,
                this.ballot.gamesOccuringBetweenEndDate,
                '0'
              )
              .then(res => {
                if(res && !res.hasError) {
                  try {
                    const gameStats = JSON.parse(res.data)
                    n.totals = gameStats.games.find(gs => gs.opponent == "Totals")
                  }
                  catch (e) {
                    console.error(`Encountered the following error when fetching game stats for ${n.apiPlayerName}:`)
                    console.error(e)
                    n.hasStats = false
                  }
                }
                else {
                  n.hasStats = false
                }
              })
          })
        )

        this.statsNotLoaded = false
      }

      const rows = []
      this.tables.forEach(table => {
        table.nominations.forEach(n => {
          const row = {
            TeamName: table.teamName,
            Nominee: n.fullName,
            Award: n.awardType.title,
            NominationDate: this.formatDate(n.modifiedOn),
            Nominator: `${n.nominator.firstName} ${n.nominator.lastName}`,
          }

          if(this.exportWithStats) {
            const statsSource = n.totals // Leaving this indirection in case we end up using different sources again.
            this.allStatGroupsInBallot.forEach(sg => {
              sg.stats.forEach(stat => {
                let nominationHasStat =
                  n.award.statGroups.find(a_sg => a_sg.id == sg.id) != null // Only include stats from specified stat groups.
                  && statsSource != null
                  && statsSource[stat.groupMapping] != null
                  && statsSource[stat.groupMapping][stat.nameMapping] != null

                row[`${sg.name} - ${stat.name}`] = nominationHasStat
                  ? statsSource[stat.groupMapping][stat.nameMapping]
                  : "-"
              })
            })
          }
          if(this.exportWithSupporting) {
            row['Jersey'] = n.jersey || "-"
            row['Position'] = n.position || "-"
            row['Class year'] = n.classYear || "-"
            row['Hometown'] = n.hometown ? `"${n.hometown}"` : "-"
            row['High School'] = n.highSchool ? `"${n.highSchool}"` : "-"
            if(this.ballot.useCustomField) {
              row[this.ballot.customFieldLabel] = n.customField ? `"${n.customField}"` : "-"
            }
            let html = n.supportingInformation || '-'
            html = html.replace(/<\/div>/ig, '');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '');
            html = html.replace(/<p>/ig, '');
            html = html.replace(/<[^>]+>/ig, '');
            html = html.replace(/&nbsp;/ig, ' ');
            // html = html.replace(/#/g, 'number ');
            row['Supporting information'] = `"${html}"`
          }

          rows.push(row)
        })
      })

      CsvClient.Write(rows, `NominationResults-${this.ballot.title}`)
      this.exportNominationsLoading = false
    },

    openDeleteModal(nomination, table) {
      this.nominationUpForDeletion = nomination
      this.nominationUpForDeletionTable = table
      this.deleteModal = true
    },

    // eslint-disable-next-line no-unused-vars
    async deleteNomination(nomination, table) {
      this.nominationDeleteLoading = true

      if (nomination?.length) {
        let errorDeleted = [];        
        for (let i = 0; i < nomination?.length; i++) {
          const res = await Controllers.NominationController.AdminDeleteNomination(nomination[i])
          if(!res && res.hasError) errorDeleted.push(nomination[i])
        }        
        if (errorDeleted.length > 0) this.$root.showSnackbar('There was an error deleting the nominations.', "error", 5000)          
        else this.$root.showSnackbar('Nominations deleted successfully!', "success", 5000)
      } else {
        const res = await Controllers.NominationController.AdminDeleteNomination(nomination.id)
        if(res && !res.hasError) this.$root.showSnackbar('Nomination deleted successfully!', "success", 5000)
        else this.$root.showSnackbar('There was an error deleting the nomination.', "error", 5000)
      } 

      this.getBallot();      

      this.nominationDeleteLoading = false
      this.deleteModal = false
      this.nominationUpForDeletion = null
      this.nominationUpForDeletionTable = null
    },

    async getBallot() {
      const ballotRes = await Controllers.BallotController.AdminGetBallot(this.ballotId)

      if(ballotRes && !ballotRes.hasError) {
        const ballot = ballotRes.data

        // Table { teamName, nominations : [{...nomination, awardType}]}

        const nominationsByTeamName = {}
        
        // Link nominations to their award types + a couple data translations.
        ballot.awards.forEach(a => {
          a.nominations.forEach(n => {
            const fName = n.firstName.split('/')
            const lName = n.lastName.split('/')
            let fullName = `${fName[0] } ${lName[0]}`
            if (fName.length > 1 ){ 
              fullName += ` /  ${fName[1]} ${lName[1]}`
            }
            n.award = a
            n.awardType = a.awardType
            n.fullName = fullName
            n.createdOn = new Date(n.createdOn)
            n.modifiedOn = new Date(n.modifiedOn)
          })
        })

        const nominations = ballot.awards.map(a => a.nominations).flat()
        nominations.forEach(n => {
          if(nominationsByTeamName[n.teamName]) nominationsByTeamName[n.teamName].push(n)
          else nominationsByTeamName[n.teamName] = [n]
        })

        this.tables = Object.entries(nominationsByTeamName).map(([teamName, nominations]) => {
          return {
            teamName,
            nominations
          }
        })

        this.ballot = ballot
      }
      else {
        // Error handling
      }

      this.loaded = true
    }

  },

  async created() {
    this.$root.updateTitle("Nomination Results")
    this.getBallot();    
  }
}
</script>

<style lang="scss">
.panel-with-no-padding .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>